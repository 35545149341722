// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-archives-js": () => import("./../src/templates/blog-archives.js" /* webpackChunkName: "component---src-templates-blog-archives-js" */),
  "component---src-templates-minne-js": () => import("./../src/templates/minne.js" /* webpackChunkName: "component---src-templates-minne-js" */),
  "component---src-templates-blog-tags-js": () => import("./../src/templates/blog-tags.js" /* webpackChunkName: "component---src-templates-blog-tags-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-minne-js": () => import("./../src/pages/minne.js" /* webpackChunkName: "component---src-pages-minne-js" */)
}

