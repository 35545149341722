module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/home/say/Workspaces/indregard.no/node_modules/gatsby-remark-unwrap-images","id":"971fabea-81a8-5611-933a-539f23b623a8","name":"gatsby-remark-unwrap-images","version":"1.0.1","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/say/Workspaces/indregard.no/node_modules/gatsby-remark-images","id":"3626c0e0-58d4-5046-b0e5-cb7731a052f2","name":"gatsby-remark-images","version":"3.1.44","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":["gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200,"showCaptions":true,"markdownCaptions":false}},{"resolve":"gatsby-remark-audio","options":{"controls":true,"loop":false,"autoplay":false,"muted":false,"preload":"auto"}},{"resolve":"gatsby-remark-vscode"},"gatsby-remark-katex","gatsby-remark-copy-linked-files","gatsby-remark-numbered-footnotes",{"resolve":"gatsby-remark-embedder","options":{"customTransformers":[{},{}]}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"indregard.no","short_name":"indregard","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-square.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-owa/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"95d485c5f2161f51287daeacba2f757c","owaUrl":"https://stats.indregard.no/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
